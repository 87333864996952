body {
  height: 100%;
  overflow-y: hidden;
}

@keyframes message {
  0% {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.app {
  height: 100vh;
  position: absolute;
  width: 100%;

  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    transition: opacity 1s;
    opacity: 0;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &.play {
    .actions {
      opacity: 0;
      pointer-events: none;
    }

    &:before {
      opacity: 1;
      transition: opacity 1s;
    }
  }

  &.ready {
    &:before {
      opacity: 0;
      transition: opacity 1s 1s;
    }

    &:after {
      opacity: 0;
    }

    canvas {
      // transform: translate(0, 0);
    }

    &.actions {
      opacity: 0;
    }
  }

  &:before {
    $size: 150px;

    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: url("/loader.gif") no-repeat center center / $size $size;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: opacity 4s 2s;
    opacity: 1;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &.switch-view .mask {
    opacity: 1;
  }

  audio {
    display: none;
  }

  .audio-controller {
    position: fixed;
    bottom: 1em;
    right: 1em;
    width: 2em;
    height: 1em;
    cursor: pointer;

    &.play {
      &:after {
        height: 100%;
      }
    }

    &:after {
      content: "";
      background: url("/sound.gif") no-repeat center center / 100% auto;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 10%;
    }
  }

  .actions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    transition: opacity 0.5s;
    z-index: 2;

    button {
      display: block;
      margin-bottom: 1em;
      font-size: 2em;
      padding: 0.5em;
      width: 100%;
    }
  }

  .chat {
    width: 30%;
    max-width: 500px;
    position: fixed;
    right: 2%;
    top: 0;
    bottom: 1em;
    border-radius: 2em;
    transition: opacity 0.5s;
    opacity: 0;
    box-sizing: border-box;
    pointer-events: none;

    @media screen and (max-width: 640px) {
      left: 2%;
      width: auto;
    }

    // &:before {
    //   content: "";
    //   background-color: white;
    //   height: 2em;
    //   display: block;
    //   width: 100%;
    //   border-top-left-radius: 2em;
    //   border-top-right-radius: 2em;
    // }

    .close {
      $size: 3rem;

      position: absolute;
      text-align: center;
      width: $size;
      height: $size;
      line-height: $size / 1.3;
      top: 7rem;
      right: 0.25em;
      cursor: pointer;
      font-size: 3em;
      color: white;
      background-color: #aaa;
      border-radius: 100%;
      transition: transform 0.25s;

      &:hover {
        transform: scale(1.1, 1.1) rotate(180deg);
      }
    }

    .picture {
      position: absolute;
      width: 150px;
      height: 200px;
      top: 0rem;
      left: 50%;
      transform: translate(-50%, 0);
      // background-color: white;
    }

    svg {
      // position: absolute;
      // left: 50%;
      // transform: translate(-50%, 0);
      margin-top: 1rem;
      display: block;
    }

    .comments {
      width: 100%;
      background-color: white;
      width: 100%;
      height: 70%;
      margin: 0;
      position: relative;
      float: left;
      border-bottom-left-radius: 2em;
      border-bottom-right-radius: 2em;
      padding: 0 1em 2em;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .message {
      background-color: #aaa;
      padding: 1em 2em;
      color: white;
      box-sizing: border-box;
      border-radius: 2em;
      display: inline-block;
      margin: 0.25em 0.5em;
      width: fit-content;
      font-size: 1.2em;
      // width: 100%;
      animation: message 0.5s;
      max-width: 80%;

      &.player {
        margin-left: auto;
      }
    }

    .answers {
      position: absolute;
      bottom: 1em;
      width: 100%;
      left: 0;
      text-align: center;

      .message {
        margin: 0 1em;
        cursor: pointer;

        &:hover {
          background-color: #bbb;
        }
      }
    }
  }

  &.chatting .chat {
    opacity: 1;
    pointer-events: all;
  }
}

.label {
  position: absolute;
  display: inline-block;
  border: 1px solid gray;
  background-color: white;
  padding: 0.5em;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 20%;
  opacity: 0.5;
}
